// source: protobuf/history/history.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.history.EventType', null, global);
goog.exportSymbol('proto.history.HistoryFieldResponse', null, global);
goog.exportSymbol('proto.history.HistoryFieldValue', null, global);
goog.exportSymbol('proto.history.HistorySFieldResponse', null, global);
goog.exportSymbol('proto.history.ListHistoryRequest', null, global);
goog.exportSymbol('proto.history.ListHistoryResponse', null, global);
goog.exportSymbol('proto.history.ListHistorySResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.HistoryFieldValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.HistoryFieldValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.HistoryFieldValue.displayName = 'proto.history.HistoryFieldValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.ListHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.ListHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.ListHistoryRequest.displayName = 'proto.history.ListHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.HistoryFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.HistoryFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.HistoryFieldResponse.displayName = 'proto.history.HistoryFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.ListHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.history.ListHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.history.ListHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.ListHistoryResponse.displayName = 'proto.history.ListHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.HistorySFieldResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.history.HistorySFieldResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.HistorySFieldResponse.displayName = 'proto.history.HistorySFieldResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.history.ListHistorySResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.history.ListHistorySResponse.repeatedFields_, null);
};
goog.inherits(proto.history.ListHistorySResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.history.ListHistorySResponse.displayName = 'proto.history.ListHistorySResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.HistoryFieldValue.prototype.toObject = function(opt_includeInstance) {
  return proto.history.HistoryFieldValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.HistoryFieldValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.HistoryFieldValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    v: (f = msg.getV()) && google_protobuf_struct_pb.Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.HistoryFieldValue}
 */
proto.history.HistoryFieldValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.HistoryFieldValue;
  return proto.history.HistoryFieldValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.HistoryFieldValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.HistoryFieldValue}
 */
proto.history.HistoryFieldValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_struct_pb.Value;
      reader.readMessage(value,google_protobuf_struct_pb.Value.deserializeBinaryFromReader);
      msg.setV(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.HistoryFieldValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.HistoryFieldValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.HistoryFieldValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.HistoryFieldValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getV();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_struct_pb.Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Value v = 1;
 * @return {?proto.google.protobuf.Value}
 */
proto.history.HistoryFieldValue.prototype.getV = function() {
  return /** @type{?proto.google.protobuf.Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Value, 1));
};


/**
 * @param {?proto.google.protobuf.Value|undefined} value
 * @return {!proto.history.HistoryFieldValue} returns this
*/
proto.history.HistoryFieldValue.prototype.setV = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistoryFieldValue} returns this
 */
proto.history.HistoryFieldValue.prototype.clearV = function() {
  return this.setV(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistoryFieldValue.prototype.hasV = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.ListHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.history.ListHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.ListHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.ListHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.ListHistoryRequest}
 */
proto.history.ListHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.ListHistoryRequest;
  return proto.history.ListHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.ListHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.ListHistoryRequest}
 */
proto.history.ListHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.ListHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.ListHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.ListHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.ListHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.history.ListHistoryRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.history.ListHistoryRequest} returns this
*/
proto.history.ListHistoryRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.ListHistoryRequest} returns this
 */
proto.history.ListHistoryRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.ListHistoryRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.HistoryFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.history.HistoryFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.HistoryFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.HistoryFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldBindName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primaryKeys: (f = msg.getPrimaryKeys()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    relations: (f = msg.getRelations()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    dbFieldName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    beforeFormatted: jspb.Message.getFieldWithDefault(msg, 10, ""),
    afterFormatted: jspb.Message.getFieldWithDefault(msg, 11, ""),
    before: (f = msg.getBefore()) && proto.history.HistoryFieldValue.toObject(includeInstance, f),
    after: (f = msg.getAfter()) && proto.history.HistoryFieldValue.toObject(includeInstance, f),
    actionById: jspb.Message.getFieldWithDefault(msg, 15, ""),
    actionAt: (f = msg.getActionAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.HistoryFieldResponse}
 */
proto.history.HistoryFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.HistoryFieldResponse;
  return proto.history.HistoryFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.HistoryFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.HistoryFieldResponse}
 */
proto.history.HistoryFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldBindName(value);
      break;
    case 4:
      var value = /** @type {!proto.history.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 6:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPrimaryKeys(value);
      break;
    case 7:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setRelations(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDbFieldName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeforeFormatted(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterFormatted(value);
      break;
    case 13:
      var value = new proto.history.HistoryFieldValue;
      reader.readMessage(value,proto.history.HistoryFieldValue.deserializeBinaryFromReader);
      msg.setBefore(value);
      break;
    case 14:
      var value = new proto.history.HistoryFieldValue;
      reader.readMessage(value,proto.history.HistoryFieldValue.deserializeBinaryFromReader);
      msg.setAfter(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionById(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActionAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.HistoryFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.HistoryFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.HistoryFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.HistoryFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFieldBindName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimaryKeys();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getRelations();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getDbFieldName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBeforeFormatted();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAfterFormatted();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBefore();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.history.HistoryFieldValue.serializeBinaryToWriter
    );
  }
  f = message.getAfter();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.history.HistoryFieldValue.serializeBinaryToWriter
    );
  }
  f = message.getActionById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getActionAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 model_id = 2;
 * @return {number}
 */
proto.history.HistoryFieldResponse.prototype.getModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string field_bind_name = 3;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getFieldBindName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setFieldBindName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EventType event_type = 4;
 * @return {!proto.history.EventType}
 */
proto.history.HistoryFieldResponse.prototype.getEventType = function() {
  return /** @type {!proto.history.EventType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.history.EventType} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string action_id = 5;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Struct primary_keys = 6;
 * @return {?proto.google.protobuf.Struct}
 */
proto.history.HistoryFieldResponse.prototype.getPrimaryKeys = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 6));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.history.HistoryFieldResponse} returns this
*/
proto.history.HistoryFieldResponse.prototype.setPrimaryKeys = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.clearPrimaryKeys = function() {
  return this.setPrimaryKeys(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistoryFieldResponse.prototype.hasPrimaryKeys = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Struct relations = 7;
 * @return {?proto.google.protobuf.Struct}
 */
proto.history.HistoryFieldResponse.prototype.getRelations = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 7));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.history.HistoryFieldResponse} returns this
*/
proto.history.HistoryFieldResponse.prototype.setRelations = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.clearRelations = function() {
  return this.setRelations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistoryFieldResponse.prototype.hasRelations = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string db_field_name = 8;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getDbFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setDbFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string field_name = 9;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string before_formatted = 10;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getBeforeFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setBeforeFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string after_formatted = 11;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getAfterFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setAfterFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional HistoryFieldValue before = 13;
 * @return {?proto.history.HistoryFieldValue}
 */
proto.history.HistoryFieldResponse.prototype.getBefore = function() {
  return /** @type{?proto.history.HistoryFieldValue} */ (
    jspb.Message.getWrapperField(this, proto.history.HistoryFieldValue, 13));
};


/**
 * @param {?proto.history.HistoryFieldValue|undefined} value
 * @return {!proto.history.HistoryFieldResponse} returns this
*/
proto.history.HistoryFieldResponse.prototype.setBefore = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.clearBefore = function() {
  return this.setBefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistoryFieldResponse.prototype.hasBefore = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional HistoryFieldValue after = 14;
 * @return {?proto.history.HistoryFieldValue}
 */
proto.history.HistoryFieldResponse.prototype.getAfter = function() {
  return /** @type{?proto.history.HistoryFieldValue} */ (
    jspb.Message.getWrapperField(this, proto.history.HistoryFieldValue, 14));
};


/**
 * @param {?proto.history.HistoryFieldValue|undefined} value
 * @return {!proto.history.HistoryFieldResponse} returns this
*/
proto.history.HistoryFieldResponse.prototype.setAfter = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.clearAfter = function() {
  return this.setAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistoryFieldResponse.prototype.hasAfter = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string action_by_id = 15;
 * @return {string}
 */
proto.history.HistoryFieldResponse.prototype.getActionById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.setActionById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp action_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.history.HistoryFieldResponse.prototype.getActionAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.history.HistoryFieldResponse} returns this
*/
proto.history.HistoryFieldResponse.prototype.setActionAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistoryFieldResponse} returns this
 */
proto.history.HistoryFieldResponse.prototype.clearActionAt = function() {
  return this.setActionAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistoryFieldResponse.prototype.hasActionAt = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.history.ListHistoryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.ListHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.history.ListHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.ListHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.ListHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    modelsMap: (f = msg.getModelsMap()) ? f.toObject(includeInstance, undefined) : [],
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.history.HistoryFieldResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.ListHistoryResponse}
 */
proto.history.ListHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.ListHistoryResponse;
  return proto.history.ListHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.ListHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.ListHistoryResponse}
 */
proto.history.ListHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = msg.getModelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 3:
      var value = new proto.history.HistoryFieldResponse;
      reader.readMessage(value,proto.history.HistoryFieldResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.ListHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.ListHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.ListHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.ListHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getModelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.history.HistoryFieldResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.history.ListHistoryResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.history.ListHistoryResponse} returns this
*/
proto.history.ListHistoryResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.ListHistoryResponse} returns this
 */
proto.history.ListHistoryResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.ListHistoryResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<int64, string> models = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.history.ListHistoryResponse.prototype.getModelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.history.ListHistoryResponse} returns this
 */
proto.history.ListHistoryResponse.prototype.clearModelsMap = function() {
  this.getModelsMap().clear();
  return this;
};


/**
 * repeated HistoryFieldResponse items = 3;
 * @return {!Array<!proto.history.HistoryFieldResponse>}
 */
proto.history.ListHistoryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.history.HistoryFieldResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.history.HistoryFieldResponse, 3));
};


/**
 * @param {!Array<!proto.history.HistoryFieldResponse>} value
 * @return {!proto.history.ListHistoryResponse} returns this
*/
proto.history.ListHistoryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.history.HistoryFieldResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.history.HistoryFieldResponse}
 */
proto.history.ListHistoryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.history.HistoryFieldResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.history.ListHistoryResponse} returns this
 */
proto.history.ListHistoryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.HistorySFieldResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.history.HistorySFieldResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.HistorySFieldResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.HistorySFieldResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldBindName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dbFieldName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fieldName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    beforeFormatted: jspb.Message.getFieldWithDefault(msg, 10, ""),
    afterFormatted: jspb.Message.getFieldWithDefault(msg, 11, ""),
    actionById: jspb.Message.getFieldWithDefault(msg, 15, ""),
    actionAt: (f = msg.getActionAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.HistorySFieldResponse}
 */
proto.history.HistorySFieldResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.HistorySFieldResponse;
  return proto.history.HistorySFieldResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.HistorySFieldResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.HistorySFieldResponse}
 */
proto.history.HistorySFieldResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldBindName(value);
      break;
    case 4:
      var value = /** @type {!proto.history.EventType} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDbFieldName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeforeFormatted(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterFormatted(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionById(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActionAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.HistorySFieldResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.HistorySFieldResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.HistorySFieldResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.HistorySFieldResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFieldBindName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDbFieldName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBeforeFormatted();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAfterFormatted();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getActionById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getActionAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 model_id = 2;
 * @return {number}
 */
proto.history.HistorySFieldResponse.prototype.getModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string field_bind_name = 3;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getFieldBindName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setFieldBindName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional EventType event_type = 4;
 * @return {!proto.history.EventType}
 */
proto.history.HistorySFieldResponse.prototype.getEventType = function() {
  return /** @type {!proto.history.EventType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.history.EventType} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string action_id = 5;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string db_field_name = 7;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getDbFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setDbFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string field_name = 8;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string before_formatted = 10;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getBeforeFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setBeforeFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string after_formatted = 11;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getAfterFormatted = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setAfterFormatted = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string action_by_id = 15;
 * @return {string}
 */
proto.history.HistorySFieldResponse.prototype.getActionById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.setActionById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp action_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.history.HistorySFieldResponse.prototype.getActionAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.history.HistorySFieldResponse} returns this
*/
proto.history.HistorySFieldResponse.prototype.setActionAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.HistorySFieldResponse} returns this
 */
proto.history.HistorySFieldResponse.prototype.clearActionAt = function() {
  return this.setActionAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.HistorySFieldResponse.prototype.hasActionAt = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.history.ListHistorySResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.history.ListHistorySResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.history.ListHistorySResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.history.ListHistorySResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.ListHistorySResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    modelsMap: (f = msg.getModelsMap()) ? f.toObject(includeInstance, undefined) : [],
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.history.HistorySFieldResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.history.ListHistorySResponse}
 */
proto.history.ListHistorySResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.history.ListHistorySResponse;
  return proto.history.ListHistorySResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.history.ListHistorySResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.history.ListHistorySResponse}
 */
proto.history.ListHistorySResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = msg.getModelsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 3:
      var value = new proto.history.HistorySFieldResponse;
      reader.readMessage(value,proto.history.HistorySFieldResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.history.ListHistorySResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.history.ListHistorySResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.history.ListHistorySResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.history.ListHistorySResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getModelsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.history.HistorySFieldResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.history.ListHistorySResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.history.ListHistorySResponse} returns this
*/
proto.history.ListHistorySResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.history.ListHistorySResponse} returns this
 */
proto.history.ListHistorySResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.history.ListHistorySResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<int64, string> models = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.history.ListHistorySResponse.prototype.getModelsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.history.ListHistorySResponse} returns this
 */
proto.history.ListHistorySResponse.prototype.clearModelsMap = function() {
  this.getModelsMap().clear();
  return this;
};


/**
 * repeated HistorySFieldResponse items = 3;
 * @return {!Array<!proto.history.HistorySFieldResponse>}
 */
proto.history.ListHistorySResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.history.HistorySFieldResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.history.HistorySFieldResponse, 3));
};


/**
 * @param {!Array<!proto.history.HistorySFieldResponse>} value
 * @return {!proto.history.ListHistorySResponse} returns this
*/
proto.history.ListHistorySResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.history.HistorySFieldResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.history.HistorySFieldResponse}
 */
proto.history.ListHistorySResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.history.HistorySFieldResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.history.ListHistorySResponse} returns this
 */
proto.history.ListHistorySResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * @enum {number}
 */
proto.history.EventType = {
  UNKNOWN: 0,
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
  RESTORE: 4
};

goog.object.extend(exports, proto.history);
